import GameLayout from "@/components/layouts/GameLayout.vue";
import route from '@/library/routes'
import {defineAsyncComponent} from "vue";

export default route('game/colonies', {layout: GameLayout, authRequired: true}, [
	{
		path: '/:colonyId',
		component: () => import('@/modules/colonies/Colony.vue'),
    children: [
      {
        path: '',
        name: 'colony.overview',
        component: () => import('@/modules/colonies/Overview.vue'),
	      meta: {
					hideCelestialBody: false,
	      }
      },
      {
        path: 'infrastructure',
        name: 'colony.infrastructure',
        component: () => import('@/modules/colonies/Infrastructure.vue'),
	      meta: {
		      hideCelestialBody: false,
					rightPanels: defineAsyncComponent(() => import('@/modules/colonies/infrastructure/panels/SelectedHex.vue'))
				}
      },
	    {
		    path: 'system/:systemId',
		    name: 'colony.system',
		    component: () => import('@/modules/colonies/System.vue'),
		    meta: {
			    hideCelestialBody: true,
			    rightPanels: defineAsyncComponent(() => import('@/modules/colonies/system/panels/CelestialBodies.vue'))
		    }
	    },
    ]
	}
])
