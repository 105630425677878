import GameLayout from "@/components/layouts/GameLayout.vue";
import route from '@/library/routes'

export default route('game/celestial-bodies', {layout: GameLayout, authRequired: true}, [
	{
		path: '/:celestialBodyId',
		component: () => import('@/modules/celestial-bodies/ViewCelestialBody.vue'),
    name: 'celestial-bodies.view'
	}
])
