<template>
  <SettingsLayout>
    <template #info>
      <p>Game notifications occur at various times, including when you're offline.</p>
      <p>Configure your preferred notifications when offline.</p>
    </template>

    <template #settings>
      <div class="space-y-12">
        <div>
          <h2 class="text-2xl mb-6">When offline...</h2>
          <p class="text-sm text-gray-400">Receive in-game notifications to your preferred channel, such as project completion, incoming hostile fleet, colony established.etc.</p>
        </div>

        <div class="space-y-6">
          <div class="flex items-center space-x-6">
            <Toggle :action="() => toggle('email')" :on="hasChannel('email')"/>
            <div>
              <p>Receive notifications by email.</p>
            </div>
          </div>
          <div class="flex items-center space-x-6">
            <Toggle :action="() => toggle('push')" :on="hasChannel('push')"/>
            <p>Receive push notifications.</p>
          </div>
        </div>
      </div>
    </template>
  </SettingsLayout>
</template>

<script setup lang="ts">
  import SettingsLayout from "@/modules/identity/account/settings/SettingsLayout.vue"
  import Toggle from "@/components/form/Toggle.vue"
  import {usePlayerStore} from "@/modules/identity/player"
  import {toggleNotificationChannel} from "@/modules/identity/account/profile";

  const playerStore = usePlayerStore()
  const { hasChannel, toggleChannel } = playerStore

  function toggle(channel: string) {
    toggleChannel(channel)
    toggleNotificationChannel(channel)
  }
</script>