<template>
  <button class="group" :class="roundedClass" :disabled :type @click="handler">
    <span class="rounded-[inherit] shadow-outer-glow shadow-transparent group-active:shadow-outer-glow-lg transition duration-300 group-active:duration-0 min-h-[inherit] w-full" :class="state">
      <span class="border border-gray-500 flex justify-center items-center space-x-1.5 backdrop-blur-sm gap-2 transition duration-300 font-medium ring-0 group-hover:ring-2 group-active:duration-0 shadow-inner-glow ring-transparent min-h-[inherit] w-full rounded-[inherit]" :class="padding">
        <slot/>
      </span>
    </span>
  </button>
</template>

<script setup lang="ts">
  import {computed, ref} from "vue"
  import {useRouter} from "vue-router"

  const router = useRouter()

  const props = withDefaults(defineProps<{
    active?: boolean,
    disabled?: boolean,
    padding?: string,
    rounded?: boolean,
    type?: string,
    action?: Function|object|string,
  }>(), {
    active: false,
    padding: 'p-2 px-4',
    rounded: false,
    type: 'button',
  });

  const roundedClass = props.rounded ? 'rounded-full' : 'rounded-sm';
  const ready = ref(true)

  // button can do many things - it could push a user to a new route, it might be acting on a async request, who the hell knows! So,
  // we have to be smart about how we manage the desired action - if it's a string or object, it's likely a route requirement. If
  // it's a function, then we're dealing with (likely) an async request. In either case, in the latter we can manage the button's
  // internal state for a good user experience, such as disabling the button until such time as a response is returned.
  const handler = () => {
    if (typeof props.action === 'undefined') {
      return
    }

    if (typeof props.action === 'function') {
      ready.value = false
      const result = props.action()

      if (result instanceof Promise) {
        result.then(() => {
          ready.value = true
        })
      }
      else {
        ready.value = true
      }

      return
    }

    // we're dealing with a route of some description, could be a route object, or a string
    router.push(props.action)
  }

  // Determines the CSS class state to be applied
  const state = computed<string>(() => {
    if (props.active) return 'active'
    if (props.disabled || !ready.value) return 'disabled'
    return 'ready'
  })
</script>

<style lang="postcss" scoped>
  .active {
    @apply shadow-sky-600/30;
  }

  .active > span {
    @apply text-sky-500;
    @apply border-sky-500;
    @apply ring-sky-600/50;
    @apply shadow-sky-600/20;
  }

  .disabled > span {
    @apply opacity-50;
  }

  .ready {
    @apply group-active:shadow-sky-600/30;
  }

  .ready > span {
    @apply group-active:text-sky-500;
    @apply group-hover:border-white/90 group-active:border-sky-500;
    @apply group-hover:ring-white/50 group-active:ring-sky-600/50;
    @apply group-hover:shadow-white/30 group-active:shadow-sky-600/20;
  }
</style>