import {defineStore} from "pinia"
import {useStorage} from '@vueuse/core'
import {computed} from "vue"

interface Session {
	galaxyId?: string|null
	playerId?: string|null
	token?: string|null
}

export const useSessionStore = defineStore('session', () => {
	const session = useStorage<Session>('session', {})
	
	const clearToken = () => session.value.token = null
	const galaxyId = computed(() => session.value.galaxyId)
	const guest = computed(() => !session.value.token)
	const logout = () => session.value = null
	const setGalaxyId = (galaxyId: string) => session.value.galaxyId = galaxyId
	const setPlayerId = (playerId: string) => session.value.playerId = playerId
	const setToken = (token: string) => session.value.token = token
	const token = computed(() => session.value.token)
	
	return {
		clearToken,
		galaxyId,
		guest,
		logout,
		session,
		setGalaxyId,
		setPlayerId,
		setToken,
		token,
	}
})