import {defineStore} from "pinia"
import {ref} from "vue";

export const useAccountStore = defineStore('account', () => {
	const accountPanelOpen = ref(false)
	
	function openAccountPanel() {
		accountPanelOpen.value = true
	}
	
	function closeAccountPanel() {
		accountPanelOpen.value = false
	}
	
	return {
		accountPanelOpen,
		closeAccountPanel,
		openAccountPanel,
	}
})
