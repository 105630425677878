import StartLayout from "@/components/layouts/SplashLayout.vue";

export default [
	{
		path: '/',
		component: () => import('@/modules/Home.vue'),
		name: 'home',
		meta: { layout: StartLayout, title: 'Halcyon Online' }
	},
  {
    path: '/hexmap',
    component: () => import('@/components/hexmap/HexMap.vue'),
    name: 'hexmap',
    meta: { layout: StartLayout }
  },
]
