const offset = 20
const tooltip = document.createElement('div')
const span = document.createElement('span')

tooltip.appendChild(span)

const updatePosition = (e) => {
	if (e.pageX > window.innerWidth / 2) {
		tooltip.style.left = (e.pageX - tooltip.getBoundingClientRect().width - offset) + 'px'
	}
	else {
		tooltip.style.left = (e.pageX + offset)+'px'
	}
	
	tooltip.style.top = (e.pageY + offset)+'px'
}

/**
 * The tooltip directive allows us to attach hints/tooltips to vue components with custom text, as required. This is useful
 * in highlighting symbols, icons, images and providing further information for those elements, or by providing additional
 * context for a given game state, such as a disabled button, by providing details as to why the button is disabled.
 */
export default function(el, binding) {
	tooltip.classList.add('absolute', 'bg-black', 'backdrop-blur', 'p-1', 'px-2', 'text-xs', 'text-primary-300', 'hidden', 'z-main-400');
	
	document.body.appendChild(tooltip);
	
	el.addEventListener('mouseover', (e) => {
		if (binding.value) {
			tooltip.textContent = binding.value
			updatePosition(e)
			tooltip.classList.remove('hidden')
		}
		else {
			tooltip.textContent = binding.value
			tooltip.classList.add('hidden')
		}
	})
	
	el.addEventListener('mousemove', (e) => {
		updatePosition(e)
	})
	
	el.addEventListener('mouseout', (e) => {
		tooltip.classList.add('hidden')
	})
}