import axios, {type AxiosResponse} from 'axios'
import type {Router} from "vue-router"
import {useSessionStore} from "@/modules/identity/authentication/session"
import {env} from "@/library/config"
import {useNotificationStore} from "@/components/notifications/notifications"

export interface AuthTokenResponse extends AxiosResponse {
    token: string
    playerId: string
}

const instance = axios.create({
    baseURL: env("VITE_GAME_SERVER_URL"),
    headers: {
        "Content-type": "application/json"
    },
    withCredentials: true,
    withXSRFToken: true,
})

instance.interceptors.request.use(
    config => {
        if (!config.headers.Authorization) {
            const sessionStore = useSessionStore()

            if (sessionStore.token) {
                config.headers.Authorization = `Bearer ${sessionStore.token}`
            }

            if (sessionStore.galaxyId) {
                config.headers['X-Galaxy-Id'] = sessionStore.galaxyId
            }
        }
        
        return config
    },
    error => Promise.reject(error)
)

instance.interceptors.response.use(response => {
    // @ts-ignore - think response.headers can be null/undefined
    if (response.headers.get('x-response-type') === 'notification') {
        const notificationStore = useNotificationStore()
        notificationStore.fromResponse('global', response.data)
    }
    
    return response
})

/**
 * This looks pretty funky and well... it is. What we're doing is providing
 * a setup function for Vue to use, but expecting a Router object to be
 * passed in. This allows us to register an interceptor on Axios that
 * we can use to push the player to the home page if a 401 is returned
 * from the API.
 */
export function setup(router: Router) {
    return function () {
        instance.interceptors.response.use(response => response, error => {
            if (error.response.status == 401) {
                useSessionStore().logout()
                
                router.push({name: 'home'})
            }
            
            // Trying to access a galaxy they don't have access to
            if (error.response.status == 403) {
                router.push({name: 'galaxies.list'})
            }

            return Promise.reject(error);
        })
    }
}

export default instance
