<template>
  <img :src="img" alt="Halcyon Online logo" :class="classes">
</template>

<script lang="ts" setup>
  import {computed} from 'vue';

  const props = defineProps({
    size: {
      type: String,
      default: 'full',
    },
  });

  const img = computed(() => props.size === 'full' ? '/img/logo-white.webp' : '/img/logo-small.webp')
  const classes = computed(() => props.size === 'full' ? 'w-4/5 max-w-[490px] lg:w-full' : '')
</script>