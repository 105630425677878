<template>
  <div>
    <SetPassword v-if="!authenticators.authenticators.includes('email-password')"/>
    <ModifyPassword v-else/>
  </div>
</template>

<script setup lang="ts">
	import {useAuthenticatorsStore} from '@/modules/identity/authentication/authenticators'
	import SetPassword from '@/modules/identity/account/password/SetPassword.vue'
  import ModifyPassword from "@/modules/identity/account/password/PasswordManagement.vue"

	const authenticators = useAuthenticatorsStore()
</script>
