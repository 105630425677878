<template>
  <div class="focus-within:shadow-outer-glow focus-within:shadow-white/30 bg-black/25" :class="{'focus-within:shadow-yellow-700/80': validation.$error && model}">
    <input :type :placeholder v-model="model" class="w-full placeholder:text-gray-400" :class="{'focus:border-warning-400/80 focus:shadow-warning-500/30': validation.$error && model}" />
  </div>
</template>

<script setup lang="ts">
  defineProps<{
    placeholder?: string
    type: string,
    validation?: any
  }>()

  const model = defineModel({ required: true })
</script>
