<template>
  <button class="group rounded-sm w-12" type="button" @click="action">
    <span class="rounded-[inherit] shadow-outer-glow shadow-transparent group-active:shadow-outer-glow-lg transition duration-300 group-active:duration-0 min-h-[inherit] w-full" :class="on ? 'on' : 'off'">
      <span class="border border-gray-500 flex justify-center items-center space-x-1.5 backdrop-blur-sm gap-2 transition duration-300 font-medium ring-0 group-hover:ring-2 group-active:duration-0 shadow-inner-glow ring-transparent min-h-[inherit] w-full rounded-[inherit] p-1">
        <span class="relative h-4 w-5 text-white transition duration-300 group-active:duration-0 ease-in-out group-active:bg-sky-500 group-active:text-white group-hover:bg-primary-300 group-hover:text-black p-1" :class="{'translate-x-1/2 bg-success-500': on, '-translate-x-1/2 bg-primary-700': off}">
          <Icon class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 size-3 stroke-current stroke-2 transition-opacity duration-300" :class="{'opacity-0': off, 'opacity-100': on}">
            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
          </Icon>
          <Icon class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 size-3 stroke-current stroke-2 transition-opacity duration-300" :class="{'opacity-100': off, 'opacity-0': on}">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
          </Icon>
        </span>
      </span>
    </span>
  </button>
</template>

<script setup lang="ts">
  import {computed} from "vue";
  import Icon from "@/components/Icon.vue";

  const props = withDefaults(defineProps<{
    action: Function,
    on: boolean
  }>(), {})

  const off = computed(() => !props.on)
</script>

<style lang="postcss" scoped>
  .on {
    @apply shadow-success-600/30;
  }

  .on > span {
    @apply border-success-500;
    @apply ring-success-600/50;
    @apply shadow-success-600/20;
  }

  .off {
    @apply group-active:shadow-success-600/30;
  }

  .off > span, .on > span {
    @apply group-hover:border-white/90 group-active:border-sky-500;
    @apply group-hover:ring-white/50 group-active:ring-success-600/50;
    @apply group-hover:shadow-white/30 group-active:shadow-success-600/20;
  }
</style>