<template>
  <form method="post" @submit.prevent="submissionHandler">
    <div class="space-y-1.5">
      <div class="flex">
        <label class="w-1/3 py-2">Current password</label>
        <div class="flex-grow">
          <div class="flex-grow flex items-center space-x-1.5">
            <Text :type="fieldType" v-model="fields.currentPassword" :validation="validator.currentPassword" class="flex-grow"/>
            <Button @click="toggleVisibility">
              <Eye class="size-6" :open="!passwordsVisible"/>
            </Button>
          </div>
          <div class="relative" v-if="validator.currentPassword.$dirty">
            <Errors :form-object="validator.currentPassword" class="inset w-full mx-auto bg-black/50 p-2 backdrop-blur"/>
          </div>
        </div>
      </div>

      <div class="pt-6 ml-auto w-2/3 flex items-center space-x-3">
        <Submit type="submit" :disabled="!!validator.$errors.length || !validator.$anyDirty" :waiting="inProgress" class="w-20">Remove</Submit>
        <Button @click="$emit('cancelled')">Cancel</Button>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
	import Errors from '@/components/form/Errors.vue'
	import {useAuthenticatorsStore} from '@/modules/identity/authentication/authenticators'
	import {required} from '@vuelidate/validators'
	import Button from '@/components/form/Button.vue'
  import {removePassword} from '@/modules/identity/account/profile'
  import Text from "@/components/form/Text.vue"
  import Eye from "@/components/icons/Eye.vue"
  import hasVisibility from "@/modules/identity/account/password-visibility"
  import Submit from "@/components/form/Submit.vue"
  import {useForm} from "@/components/form/form"
  import {useNotificationStore} from "@/components/notifications/notifications"
  import type {AuthenticationResponse} from '@/modules/identity/account/profile'

  const notificationStore = useNotificationStore()
  const { fromResponse } = notificationStore
	const authenticators = useAuthenticatorsStore()
  const { fieldType, passwordsVisible, toggleVisibility } = hasVisibility()

  const {fields, inProgress, validator, submissionHandler} = useForm()
    .hasFields({
      currentPassword: ''
    })
    .withRules({
      currentPassword: {
        required
      }
    })
    .whenSubmitted((fields) =>
      removePassword({...fields}).then((response: AuthenticationResponse) => {
        authenticators.authenticators = response.data.metaData.authenticators
        fromResponse('global', response.data)
      })
    )
    .create()
</script>
