import route from '@/library/routes'
import OpenLayout from "@/components/layouts/OpenLayout.vue"

export default route('game/galaxies', {layout: OpenLayout, authRequired: true}, [
	{
		path: '',
		component: () => import('@/modules/galaxy/ListGalaxies.vue'),
    name: 'galaxies.list'
	}
])
