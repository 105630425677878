<template>
	<form method="post" @submit.prevent="submissionHandler" class="block space-y-6">
    <div class="flex">
      <label class="w-1/3 py-2">Current password</label>
      <div class="flex-grow">
        <div class="flex-grow flex items-center space-x-1.5">
          <Text :type="fieldType" v-model="fields.currentPassword" :validation="validator.currentPassword" class="flex-grow"/>
          <Button @click="toggleVisibility">
            <Eye class="size-6" :open="!passwordsVisible"/>
          </Button>
        </div>
        <div class="relative" v-if="validator.currentPassword.$dirty">
          <Errors :form-object="validator.currentPassword" class="inset w-full mx-auto bg-black/50 p-2 backdrop-blur"/>
        </div>
      </div>
    </div>

    <div class="flex">
      <label class="w-1/3 py-2">New password</label>
      <div class="flex-grow">
        <Text :type="fieldType" v-model="fields.password" :validation="validator.password"/>
        <div class="relative" v-if="validator.password.$dirty">
          <Errors :form-object="validator.password" class="inset w-full mx-auto bg-black/50 p-2 backdrop-blur"/>
        </div>
      </div>
    </div>

    <div class="flex">
      <label class="w-1/3 py-2">Confirm password</label>
      <div class="flex-grow">
        <Text :type="fieldType" v-model="fields.passwordConfirmation" :validation="validator.passwordConfirmation"/>
        <div class="relative" v-if="validator.passwordConfirmation.$dirty">
          <Errors :form-object="validator.passwordConfirmation" class="inset w-full mx-auto bg-black/50 p-2 backdrop-blur"/>
        </div>
      </div>
    </div>

    <div class="pt-6 ml-auto w-2/3 flex items-center space-x-3">
      <Submit :disabled="!!validator.$errors.length || !validator.$anyDirty || inProgress" :waiting="inProgress" class="w-20">Save</Submit>
      <Button @click="$emit('cancelled')">Cancel</Button>
    </div>
	</form>
</template>

<script setup lang="ts">
	import Errors from '@/components/form/Errors.vue'
  import {computed} from 'vue'
	import Button from '@/components/form/Button.vue'
	import {minLength, required, sameAs } from '@vuelidate/validators'
  import {changePassword} from '@/modules/identity/account/profile'
  import Eye from "@/components/icons/Eye.vue"
  import Text from "@/components/form/Text.vue"
  import hasVisibility from "@/modules/identity/account/password-visibility"
  import {useNotificationStore} from "@/components/notifications/notifications"
  import Submit from "@/components/form/Submit.vue"
  import {useForm} from "@/components/form/form";
  import type {AuthenticationResponse} from '@/modules/identity/account/profile'

  const { fieldType, passwordsVisible, toggleVisibility } = hasVisibility()
  const notificationStore = useNotificationStore()
  const { fromResponse } = notificationStore
  const emit = defineEmits(['cancelled', 'done'])

  const {fields, inProgress, validator, submissionHandler} = useForm()
      .hasFields({
        currentPassword: '',
        password: '',
        passwordConfirmation: '',
      })
      .withRules((fields) => ({
        password: {
          required,
          minLength: minLength(8)
        },
        passwordConfirmation: {
          required,
          sameAsRef: sameAs(computed(() => fields.password))
        },
        currentPassword: {
          required
        }
      }))
      .whenSubmitted((fields) =>
          changePassword(fields).then((response: AuthenticationResponse) => {
            fromResponse('global', response.data)
            emit('done')
          })
      )
      .create()
</script>
