<template>
  <div class="flex space-x-24 w-full h-full">
    <div class="w-1/4 flex-shrink-0 max-w-[280px] text-lg leading-normal text-slate-300/80 space-y-6">
      <slot name="info"/>
    </div>
    <main class="space-y-12 w-full max-w-[700px]">
      <slot name="settings"/>
    </main>
  </div>
</template>
