import {defineStore} from 'pinia'
import {computed, reactive, ref} from "vue";

interface Player {
	id: string,
	email: string,
	name: string,
	alias?: string,
	notificationChannels: [string]
}

export const usePlayerStore = defineStore('player',() =>  {
	const player = ref<Player|undefined>()
	
	function hasChannel(channel: string): boolean {
		return player.value?.notificationChannels !== undefined && player.value.notificationChannels.indexOf(channel) > -1
	}
	
	function toggleChannel(channel: string) {
		if (hasChannel(channel)) {
			player.value?.notificationChannels.splice(player.value.notificationChannels.indexOf(channel), 1)
		}
		else {
			player.value?.notificationChannels.push(channel)
		}
	}
	
	function setPlayer(data: Player) {
		player.value = data
	}
	
	const ready = computed(() => !!player.email)
	
	return {
		hasChannel,
		player,
		ready,
		setPlayer,
		toggleChannel,
	}
})
