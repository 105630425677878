<template>
  <Button @click="action" :rounded="false">
    <slot/>
    <span class="text-lg">
      {{ hasProvider(provider) ? 'Remove' : 'Link' }}
    </span>
  </Button>
</template>

<script setup lang="ts">
	import {useAuthenticatorsStore} from '@/modules/identity/authentication/authenticators'
	import {removeProvider} from '@/modules/identity/account/profile'
  import Button from "@/components/form/Button.vue";
  import {storeToRefs} from "pinia";

	const props = defineProps<{provider: string}>()
	const address = import.meta.env.VITE_GAME_SERVER_URL + `/auth/social/${props.provider}/redirect`

	const authenticatorStore = useAuthenticatorsStore()
  const { hasProvider } = authenticatorStore
  const { authenticators } = storeToRefs(authenticatorStore)

	const remove = (provider:string) => {
		removeProvider(provider).then(response => {
			authenticators.value = response.data.metaData
		})
	}

  const action = () => {
    if (hasProvider(props.provider)) {
      return remove(props.provider)
    }

    window.location.href = address
  }
</script>


