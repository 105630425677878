import './assets/style.css'

import {createApp} from 'vue'
import {createPinia} from 'pinia'
import App from './App.vue'
import router from '@/modules/router'
import { setup } from "./library/axios"
import broadcasting from "@/library/broadcasting"
import eventBus from "@/library/bus"
import * as Sentry from "@sentry/vue"
import config from "@/library/config"
import tooltip from "@/components/tooltip"

const app = createApp(App)

if (config.has('SENTRY_DSN')) {
	Sentry.init({
		app,
		dsn: config.env('SENTRY_DSN'),
		integrations: [
			Sentry.browserTracingIntegration({router}),
			Sentry.replayIntegration(),
		],
		
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for tracing.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
		
		// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
		tracePropagationTargets: ["localhost", config.env('SENTRY_CLIENT_URL')],
		
		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

app.use(createPinia())
app.use(router)
app.use(setup(router))
app.use(eventBus)
app.use(broadcasting, {
	key: import.meta.env.VITE_REVERB_APP_KEY,
	wsHost: import.meta.env.VITE_REVERB_HOST,
	wsPort: import.meta.env.VITE_REVERB_PORT,
	wssPort: import.meta.env.VITE_REVERB_PORT,
	forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
})
app.directive('tooltip', tooltip)

app.mount('#app')
