import {useVuelidate} from "@vuelidate/core"
import {reactive, ref} from "vue"
import type {Ref} from "vue"
import type {UnwrapNestedRefs} from "vue";
import {getError} from "@/library/utilities";

interface Unknown {
	[key: string]: any
}

export const useForm = () => {
	let callback: Function;
	let fields: UnwrapNestedRefs<Unknown>;
	const inProgress = ref<boolean>(false)
	let rules: Ref<Unknown>
	const $externalResults = ref()
	let validator: any;
	let original: Unknown = {};
	
	// This is the handler to use in your forms. It does the wiring up of the form state across
	// the provided rules, validations, and submission state, such as whether it's in progress.
	// This helps to speed up form development as well as reduce the errors around players
	// double-clicking submit buttons.
	const submissionHandler = async (): Promise<any> => {
		inProgress.value = true
		
		const valid = await validator.value.$validate()
		
		if (!valid) {
			inProgress.value = false
			return Promise.resolve()
		}
	
		return await callback(fields.value)
			.catch(error => {
				$externalResults.value = getError(error)
			}).finally(() => {
				inProgress.value = false
			})
	}
	
	// Resets the form back to its original state
	const reset = () => {
		fields.value = {...fields.value, ...original}
	}
	
	return new class Form {
		hasFields(formFields: Unknown): this {
			original = formFields
			fields = ref(formFields)
			
			return this
		}
		
		// If provided a callback function, the callback will receive the provided fields
		// as the function's only argument, allowing you to hook validation rules into
		// field state.
		withRules(validationRules: Unknown|Function): this {
			if (typeof validationRules == 'function') {
				rules = ref(validationRules(fields));
			}
			else {
				rules = ref(validationRules)
			}
			
			return this
		}
		
		whenSubmitted(handler: Function): this {
			callback = handler
			
			return this
		}
		
		create() {
			validator = useVuelidate(rules, fields, {
				$autoDirty: true,
				$externalResults,
				$lazy: true
			})
			
			return {
				fields,
				inProgress,
				reset,
				submissionHandler,
				validator,
			}
		}
	}
}