<template>
  <div class="relative w-full h-full">
    <Borders/>
    <nav class="absolute left-1/2 top-0 -translate-x-1/2 z-main-400 w-1/5 mx-auto bg-black/50 shadow-2xl shadow-black border border-t-0 border-primary-700 p-2 px-4">
      <ol>
        <li>Empire</li>
        <li></li>
      </ol>
    </nav>
    <Suspense fallback="loading">
      <RouterView :key="$route.matched[0].path"/>
    </Suspense>
  </div>
</template>

<script setup lang="ts">
  import Borders from "@/components/ui/Borders.vue"
  import {getResponse} from "@/library/utilities"
  import {assets} from "@/modules/game-data/repository"
  import {setAssets} from "@/modules/game-data/assets"

  await assets().then(response => {
    setAssets(getResponse(response))
  })
</script>