import { createRouter, createWebHashHistory } from 'vue-router'

import Home from './home'
import Auth from "./identity/authentication"
import CelestialBodies from "@/modules/celestial-bodies"
import Colonies from "@/modules/colonies/routes"
import Identity from "@/modules/identity/routes"
import Galaxies from '@/modules/galaxy/routes'
import { guest } from '@/modules/identity/authentication/service'
import Empire from "@/modules/empire";

const router = createRouter({
	history: createWebHashHistory(),
	routes: [
		...Home,
    ...Auth,
    ...CelestialBodies,
    ...Colonies,
    ...Empire,
		...Galaxies,
		...Identity,
	]
})

/**
 * The following navigation guard runs only when the user is trying to access a route that requires
 * authentication. If the guard fails, the user is redirected to the home page.
 */
router.beforeEach((to, from) => {
	if (to.meta.authRequired && guest()) {
		return {name: 'home'}
	}
})

export default router
