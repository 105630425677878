<template>
  <SettingsLayout>
    <template #info>
      <p>Looking for more authentication options?</p>
      <p>Set a password if you'd prefer email & pass authentication, or link your Halcyon Online account with your preferred social platform.</p>
    </template>

    <template #settings>
      <div class="space-y-24">
        <div class="space-y-6">
          <h2 class="text-2xl mb-3">Password management</h2>
          <div>
            <Passwords/>
          </div>
        </div>

        <div class="space-y-6">
          <h2 class="text-2xl">Linked social accounts</h2>

          <div class="flex items-center space-x-3">
            <SocialAuthButton :provider="'google'">
              <Google class="size-6 fill-red-500 group-hover:fill-white group-active:fill-sky-500 transition duration-[inherit]"/>
            </SocialAuthButton>

            <SocialAuthButton provider="facebook">
              <Facebook class="size-6 fill-blue-500 group-hover:fill-white group-active:fill-sky-500 transition duration-[inherit]"/>
            </SocialAuthButton>

            <SocialAuthButton provider="discord">
              <Discord class="size-6 fill-indigo-500 group-hover:fill-white group-active:fill-sky-500 transition duration-[inherit]"/>
            </SocialAuthButton>

            <SocialAuthButton provider="x">
              <X class="size-6 fill-current group-hover:fill-white group-active:fill-sky-500 transition duration-[inherit]"/>
            </SocialAuthButton>
          </div>
        </div>
      </div>
    </template>
  </SettingsLayout>
</template>

<script setup lang="ts">
  import Passwords from "@/modules/identity/account/password/Passwords.vue"
  import SocialAuthButton from "@/modules/identity/account/social/SocialAuthButton.vue"
  import {authenticatorList} from "@/modules/identity/account/profile"
  import {useAuthenticatorsStore} from "@/modules/identity/authentication/authenticators";
  import SettingsLayout from "@/modules/identity/account/settings/SettingsLayout.vue";
  import Google from "@/components/icons/social/Google.vue";
  import Facebook from "@/components/icons/social/Facebook.vue";
  import Discord from "@/components/icons/social/Discord.vue";
  import X from "@/components/icons/social/X.vue";

  const authenticatorStore = useAuthenticatorsStore()

  await authenticatorList().then(response => {
    authenticatorStore.authenticators = response.data
  })
</script>