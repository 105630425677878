<template>
  <div>
    <div class="flex-grow" v-if="!show">
      <button class="inline splash-link" @click="handleShow('change')">Change</button> or
      <button class="inline splash-link" @click="handleShow('remove')">remove</button> the password on your account.
    </div>

    <Change v-if="show === 'change'" @cancelled="handleShow(false)" @done="handleShow(false)"/>
    <Remove v-if="show === 'remove'" @cancelled="handleShow(false)"/>
  </div>
</template>

<script setup lang="ts">
  import Remove from "@/modules/identity/account/password/RemovePassword.vue"
  import Change from "@/modules/identity/account/password/ChangePassword.vue"
  import {ref} from "vue"

  const show = ref<boolean|string>(false)

  const handleShow = (value:boolean|string) => {
    show.value = value
  }
</script>