import axios, {type AuthTokenResponse} from '@/library/axios'
import type {AxiosResponse} from 'axios'
import {useSessionStore} from "@/modules/identity/authentication/session"
import {storeToRefs} from "pinia"
import {joinPresence, joinPrivate} from "@/library/broadcasting";

interface Payload {
    email:string
    password?: string
}

const sessionStore = () => {
    const sessionStore = useSessionStore()
    
    return {
        ...sessionStore,
        ...storeToRefs(sessionStore)
    }
}

export const register = (email: string): Promise<AxiosResponse> => {
    return axios.post('auth/register', {email})
}

export const login = (email: string, password?: string): Promise<AuthTokenResponse> => {
    const payload:Payload = {email}

    if (password) {
        payload.password = password
    }

    return axios.post('auth/login', payload)
}

export const confirmAccount = (playerId: string, token: string): Promise<AuthTokenResponse> => {
    return axios.put(`auth/confirm/account/${playerId}/${token}`)
}

export const getToken = () => {
    return sessionStore().token.value
}

export const logout = () => {
    sessionStore().clearToken()
}

export const guest = (): boolean => {
    return !getToken()
}

export const authenticated = (): boolean => {
    return !guest()
}

export const saveSession = (response: AuthTokenResponse) => {
    sessionStore().setToken(response.token)
    sessionStore().setPlayerId(response.playerId)
    joinPrivate(response.playerId)
    joinPresence()
}

export const oneClickLogin = async (playerId: string, token: string): Promise<AuthTokenResponse> => {
    return axios.post(`auth/one-click/${playerId}/${token}`)
}

export default {
    confirmAccount,
    login,
    getToken,
    register,
    saveSession,
}
