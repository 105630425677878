/**
 * This is a simple file to provide utilities for accessing and checking against environment variables.
 */

// Return a single environment variable value
export function env(key: string): any {
	return import.meta.env[key]
}

// Check to see if the environment has a given key
export function has(key: string): boolean {
	return !!env(key)
}

export default {
	env,
	has,
}
