import GameLayout from "@/components/layouts/GameLayout.vue"
import route from '@/library/routes'

export default route('game/empire', {layout: GameLayout, authRequired: true}, [
	{
		path: '',
		component: () => import('@/modules/empire/Overview.vue'),
    name: 'empire.overview'
	}
])
