export default function(prefix: string, meta: Object, routes: Array<Object>): Array<Object> {
	return routes.map((route: Object) => {
		if (prefix) {
			route.path = '/' + prefix + route.path
		}

		route.meta = {...meta, ...route.meta || {}}

		return route
	})
}
