import type {StructureType} from "@/modules/colonies/infrastructure/types"
import type {Resources} from "@/modules/colonies/types"

export type Asset = {
	dependencies: {[key: string]: number}
	description: string
	id: string
	name: string
	type: string
}

export interface ConstructionProject extends Asset {
	project: string
	maxAllowed: number
	structure: string | null
	tiers: {[key: number]: Tier}
	total_dependencies: number
	type: StructureType
}

export interface ConstructionProjectWithTier extends ConstructionProject {
	tier: Tier
}

export type Tier = {
	level: number
	buildTime: number
	costs: Resources
	description: string
	effects: {[key: number]: number}
}

// Projects generally don't change request to request, so are stored more as a singleton object
// that can be referenced and used as required, such as with infrastructure.
let assets : {[key: string]: any} = {}

// Set the construction project data
export const setAssets = (response: {[key: string]: ConstructionProject}) => assets = response

// Returns a project at the required tier. If no tier is provided,
// it'll return the construction project at tier level 1
export const getProject = (projectId: string, tier?: number): ConstructionProjectWithTier => {
	if (tier === undefined) tier  = 1
	
	let project = assets[projectId]
	
	return {...project, tier: {...project.tiers[tier], level: tier}}
}

export const getAsset = (assetId: string): Asset => {
	return assets[assetId]
}

export const getTier = (projectId: string, tier: number): Tier | undefined => {
	const project = assets[projectId]
	
	return project.tiers[tier]
}

export const allProjects = () => Object.fromEntries(Object.entries(assets).filter(([key, asset]) => asset.type == 'structure' || asset.type == 'district'))