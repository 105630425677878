import axios, {type AuthTokenResponse} from '@/library/axios'
import type {AxiosResponse} from 'axios'
import type {NotificationResponse} from "@/library/notifications/types";


interface PasswordForm {
	currentPassword: string
}

interface ChangePasswordForm extends PasswordForm {
	password: string,
	passwordConfirmation: string,
}

interface EmailForm {
	email: string
}

export interface AuthenticationResponse extends AxiosResponse {
	data: NotificationResponse
}

interface ChangeEmailResponse extends AxiosResponse{
	token: string
}

export function profile() {
	return axios.get('profile')
}

export async function authenticatorList(): Promise<AuthenticationResponse> {
	return axios.get('profile/authenticators')
}

export function removeProvider(provider:string): Promise<AuthenticationResponse> {
	return axios.delete(`auth/social/${provider}`)
}

export function setPassword(formData: PasswordForm): Promise<AuthenticationResponse> {
	return axios.post('profile/set/password', {...formData})
}

export function changePassword(formData: ChangePasswordForm): Promise<AuthenticationResponse> {
	return axios.put('profile/change/password', {...formData})
}

export function removePassword(formData: PasswordForm): Promise<AuthenticationResponse> {
	return axios.put('profile/password', {...formData})
}

export function requestEmailChange(email: string): Promise<AxiosResponse>
{
	return axios.post('profile/change/email', {email})
}

export function toggleNotificationChannel(channel: string) {
	return axios.put('account/notification-channel', {channel})
}

export function updateContactInformation(formData: {name: string, alias: string}): Promise<AuthenticationResponse> {
	return axios.put('profile/contact-information', {...formData})
}

export function changeEmail(token: string): Promise<ChangeEmailResponse> {
	return axios.put(`profile/confirm/email/${token}`)
}

export function socialCallback(provider:string, playerId:string, socialId:string): Promise<AuthTokenResponse> {
	return axios.get(`auth/social/${provider}/${playerId}/${socialId}`)
}
