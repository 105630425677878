<template>
    <Transition
      enter-from-class="blur-sm"
      enter-active-class="transition duration-300 ease-in-out"
      enter-to-class="blur-none"
    >
      <Component :is="icon"/>
    </Transition>
</template>

<script setup lang="ts">
  import {computed} from "vue"
  import Hamburger from "@/components/icons/Hamburger.vue"
  import Close from "@/components/icons/Close.vue"

  const props = defineProps<{
    open: boolean
  }>()

  const icon = computed(() => !props.open ? Hamburger : Close)
</script>