import {computed, ref} from "vue"

export default function hasVisibility() {
	const passwordsVisible = ref(false)
	
	const fieldType = computed(() => passwordsVisible.value ? 'text' : 'password')
	
	const toggleVisibility = () => {
		passwordsVisible.value = !passwordsVisible.value
	}
	
	return {
		fieldType,
		passwordsVisible,
		toggleVisibility,
	}
}