<template>
  <div class="shadow-xl group" :class="roundedClass">
    <div class="group-active:shadow-sky-500/30 shadow-outer-glow shadow-transparent group-active:shadow-outer-glow-lg md:group-hover:shadow-white/30 transition duration-300 group-active:duration-0" :class="roundedClass">
      <slot/>
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(defineProps<{
    rounded?: boolean
  }>(), {
    rounded: false
  });

  const roundedClass = props.rounded ? 'rounded-full' : 'rounded-sm';
</script>
