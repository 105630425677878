import {defineStore} from "pinia";
import {reactive} from "vue";
import type {Notification, NotificationResponse} from "@/library/notifications/types";

export const useNotificationStore = defineStore('notifications', () => {
	const notifications = reactive<Array<Notification>>([])
	
	function pushNotification(notification: Notification) {
		notifications.push(notification)
		
		const readTime = Math.ceil(notification.message.split(/\s+/).length / 4)
		
		setTimeout(() => {
			acknowledge(notification.id)
		}, (1+readTime) * 1000)
	}
	
	function acknowledge(id: string) {
		let element: number;
		
		for (let i = 0; i < notifications.length; i++) {
			if (notifications[i].id === id) {
				element = i
				break
			}
		}
		
		if (!isNaN(element)) {
			notifications.splice(element, 1)
		}
	}
	
	// Allows us to quickly push a notification directly from a raw response object
	function fromResponse(area: string, response: NotificationResponse) {
		const notification: Notification = {area, id: window.crypto.randomUUID(), ...response}
		pushNotification(notification)
	}
	
	return {
		acknowledge,
		fromResponse,
		notifications,
		pushNotification,
	};
})