<template>
  <div class="fixed z-overlay-1000 bottom-52 -translate-x-1/2 -y-translate-1/2 left-1/2 flex justify-center w-[600px]">
    <div class="relative space-y-3 w-full">
      <TransitionGroup name="notifications">
        <button
          v-for="(notification, key) in globalNotifications"
          :key="`global-notification-${key}`"
          @click="acknowledge(notification.id)"
          class="flex items-center bg-gray-800 rounded backdrop-blur-lg min-h-20 shadow-xl w-full"
        >
          <span class="min-h-[inherit] flex items-center px-6" :class="bgColour[notification.status]">
            <Component :is="icons[notification.status]" class="size-10"/>
          </span>
          <span class="px-6 flex-grow" v-html="notification.message"></span>
          <span class="w-1 min-h-[inherit]" :class="bgColour[notification.status]"></span>
        </button>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {useNotificationStore} from "@/components/notifications/notifications";
  import {storeToRefs} from "pinia";
  import {computed} from "vue";
  import Success from "@/components/icons/Success.vue";
  import Warning from "@/components/icons/Warning.vue";
  import Info from "@/components/icons/Info.vue";
  import Error from "@/components/icons/Error.vue";

  const notificationStore = useNotificationStore()
  const { notifications } = storeToRefs(notificationStore)
  const { acknowledge } = notificationStore

  const globalNotifications = computed(() => notifications.value.filter((notification) => notification.area == 'global'))

  const bgColour: {[key: string]: string} = {
    info: 'bg-info-800',
    success: 'bg-success-800',
    warning: 'bg-warning-800',
    error: 'bg-error-800',
  }

  const icons: {[key: string]: any} = {
    error: Error,
    info: Info,
    success: Success,
    warning: Warning,
  }
</script>

<style lang="postcss" scoped>
  .notifications-enter-from {
    @apply -translate-y-20 opacity-0;
  }

  .notifications-enter-active {
    @apply transition-all duration-500;
  }

  .notifications-enter-to {
    @apply translate-y-0;
  }

  .notifications-leave-from {
    @apply opacity-100 translate-y-0;
  }

  .notifications-leave-active {
    @apply absolute transition-all duration-500;
  }

  .notifications-leave-to {
    @apply translate-y-20 opacity-0;
  }

  .notifications-move {
    @apply transition-all duration-500;
  }
</style>