import StartLayout from "@/components/layouts/SplashLayout.vue";
import route from '@/library/routes'

export default route('', {layout: StartLayout}, [
	{
		path: '/register',
		component: () => import('@/modules/identity/account/Register.vue'),
		name: 'register'
	},
	{
		path: '/login',
		component: () => import('@/modules/identity/account/LoginRegister.vue'),
		name: 'login'
	},
	{
		path: '/welcome',
		component: () => import('@/modules/identity/account/Welcome.vue'),
		name: 'welcome'
	},
	{
		path: '/account/check-email',
		component: () => import('@/modules/identity/account/OneClickSent.vue'),
		name: 'account.check-email'
	},
	{
		path: '/confirm/account/:playerId/:token',
		component: () => import('@/modules/identity/account/ConfirmAccount.vue'),
	},
	{
		path: '/profile',
		meta: { authRequired:true },
		component: () => import('@/modules/identity/account/settings/Profile.vue'),
		name: 'profile'
	},
	{
		path: '/login/:provider/:playerId/:socialId/:redirect',
		component: () => import('@/modules/identity/account/social/SocialCallback.vue'),
	},
	{
		path: '/profile/confirm/email/:token',
		component: () => import('@/modules/identity/account/email/ChangeEmail.vue')
	}
])
