<template>
  <div class="flex w-full h-full">
    <Layout :layout="$route.meta.layout">
      <slot/>
    </Layout>
    <GlobalNotification/>
    <DrawerMenu v-if="!sessionStore.guest"/>
    <ManageAccount v-if="!sessionStore.guest"/>
  </div>
</template>

<script setup lang='ts'>
	import Layout from '@/components/layouts/Layout.vue'
  import GlobalNotification from "@/components/notifications/GlobalNotification.vue"
  import DrawerMenu from "@/components/game/menu/DrawerMenu.vue"
  import {useSessionStore} from "@/modules/identity/authentication/session"
  import ManageAccount from "@/modules/identity/account/settings/ManageAccount.vue"
  import {onBeforeMount} from "vue";
  import {authenticated} from "@/modules/identity/authentication/service";
  import {joinPresence, joinPrivate} from "@/library/broadcasting";
  import {storeToRefs} from "pinia";

  const sessionStore = useSessionStore()
  const { session } = storeToRefs(sessionStore)

  onBeforeMount(() => {
    if (authenticated()) {
      joinPresence()
      joinPrivate(session.value.playerId)
    }

    // This prevents double-clicks from occurring.
    document.addEventListener('mousedown', function(event) {
      if (event.detail > 1) {
        event.preventDefault();
      }
    }, false);
  })
</script>