import mitt from 'mitt'
import type {App} from "vue";

const emitter = mitt()

export const emit = (key: string, ...args: any[]) => emitter.emit(key, ...args)
export const off = (key: string, ...args: any[]) => emitter.off(key, ...args)
export const on = (key: string, ...args: any[]) => emitter.on(key, ...args)

export default {
	install: (app: App, options?: any) => {
		app.provide('$eventBus', {$on: on, $off: off, $emit: emit})
	}
}
