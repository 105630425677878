<template>
  <SettingsLayout>
    <template #info>
      <p>Your account profile details reflect your real-life and in-game identity.</p>
      <p>If you do not wish to reveal your real name in-game, you may set an alias.</p>
    </template>

    <template #settings>
      <form class="space-y-6" @submit.prevent="submissionHandler">
        <h2 class="text-2xl">Contact information</h2>
        <div>
          <Text type="text" v-model="fields.name" :validation="validator.name" placeholder="Full name"/>
          <div class="relative" v-if="validator.name.$dirty">
            <Errors :form-object="validator.name" class="absolute inset w-full mx-auto bg-black/50 p-2 backdrop-blur" />
          </div>
        </div>

        <div>
          <Text type="text" v-model="fields.alias" :validation="validator.alias" placeholder="Alias"/>
          <div class="relative" v-if="validator.alias.$dirty">
            <Errors :form-object="validator.alias" class="absolute inset w-full mx-auto bg-black/50 p-2 backdrop-blur" />
          </div>
        </div>

        <div class="pt-6 flex items-center justify-end space-x-3">
          <Submit :disabled="!!validator.$errors.length || !validator.$anyDirty || inProgress" :waiting="inProgress">Save</Submit>
          <Button @click="reset">Reset</Button>
        </div>
      </form>

      <div>
        <h2 class="text-2xl mb-3">Email address</h2>
        <ProfileEmail/>
      </div>
    </template>
  </SettingsLayout>
</template>

<script setup lang="ts">
  import {usePlayerStore} from '@/modules/identity/player'
  import {profile, updateContactInformation} from '@/modules/identity/account/profile'
  import type {AuthenticationResponse} from "@/modules/identity/account/profile";
  import ProfileEmail from '@/modules/identity/account/email/ProfileEmail.vue'
  import Errors from "@/components/form/Errors.vue"
  import Text from "@/components/form/Text.vue"
  import SettingsLayout from "@/modules/identity/account/settings/SettingsLayout.vue"
  import Submit from "@/components/form/Submit.vue"
  import {useForm} from "@/components/form/form"
  import {required, maxLength, minLength} from "@vuelidate/validators"
  import {useNotificationStore} from "@/components/notifications/notifications"
  import Button from "@/components/form/Button.vue"
  import {storeToRefs} from "pinia";
  import {getResponse} from "@/library/utilities";

  const notificationStore = useNotificationStore()
  const { fromResponse } = notificationStore

  const playerStore = usePlayerStore()
  const { setPlayer } = playerStore
  const { player } = storeToRefs(playerStore)

  await profile().then(response => {
    setPlayer(getResponse(response))
  })

  const {fields, inProgress, reset, submissionHandler, validator} = useForm()
    .hasFields({
      name: player.value.name,
      alias: player.value.alias,
    })
    .withRules(() => ({
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20),
      },
      alias: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20),
      }
    }))
    .whenSubmitted((fields: {name: string, alias: string}) =>
        updateContactInformation(fields).then((response: AuthenticationResponse) => {
          fromResponse('global', response.data)
        })
    )
    .create()
</script>
