/**
 * Pads a string value with another string value to a certain length.
 */
export function padded(value: number|string, length: number = 2, padValue: string = '0'): string {
	if (value === undefined) {
		return ''
	}
	
	if (!Number.isNaN(value)) {
		value = value.toString()
	}
	
	return value.padStart(length, padValue)
}

/**
 * Returns a formatted version of time (from seconds) to hours:minutes:seconds.
 */
export function secondsToShortTime(seconds: number): string {
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	const remainingSeconds = Math.ceil(seconds % 60)
	let time = ''
	
	if (hours >= 1) {
		time += `${padded(hours)}:`
	}
	
	return `${time}${padded(minutes)}:${padded(remainingSeconds)}`
}

/**
 * Returns the response data from a response object.
 */
export const getResponse = (response: { data: any }) => response.data

/**
 * Returns an error from a response.
 */
export const getError = (error) => {
	const errorMessage = "API Error, please try again."
	
	if (!error.response) {
		console.error(`API ${error.config.url} not found.`)
		return errorMessage
	}
	
	if (process.env.NODE_ENV === "development") {
		console.error(error.response.data)
		console.error(error.response.status)
		console.error(error.response.headers)
	}
	
	if (error.response.data && error.response.data.errors) {
		return error.response.data.errors;
	}
	
	return errorMessage;
};

/**
 * Sorts an object's properties alphabetically, and returns the new object.
 */
export const sortObjectProperties = (unordered: any) => {
	return Object.keys(unordered).sort().reduce(
		(obj: {[key: string]: any}, key: string) => {
			obj[key] = unordered[key]
			return obj
		}, {}
	);
}

export const mapWithKeys = (obj: Object, fn: Function) =>
	Object.keys(obj).reduce((acc, k) => {
		acc[fn(obj[k], k, obj)] = obj[k];
		return acc;
	}, {});

export const get = (desired: any, defaultValue?: any): any => {
	if (desired === undefined || desired === null) {
		return defaultValue
	}
	
	return desired
}

// This is used to preload images for a given request, allowing us to show
// loaders or use Suspense component as required. //- Kirk
export const loadImage = async (src: string): Promise<any> => {
	return fetch(src, { cache: "default", mode: "cors" })
}