/**
 * A simple helper function to generate a background image url with the correct width and height based on the viewport of the client.
 * This helps to reduce the size of the image that is being downloaded by the client, as well as ensuring that we serve a background image
 * that is the most appealing.
 */
export function backgroundImage(path: string, params?: {[key: string]: string}): string {
	const query = params ? Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : ''
	
	return image(path, window.innerWidth, window.innerHeight)+`&${query}`
}

/**
 * Calls for an image from the server, resized to the required dimensions.
 */
export function image(path: string, width: number, height: number) {
	return `${import.meta.env.VITE_GAME_SERVER_URL}/${path}?w=${width}&h=${height}`
}