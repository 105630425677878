import Echo, {Channel} from 'laravel-echo'
import Pusher from 'pusher-js'
import axios from "@/library/axios"
import {useNotificationStore} from "@/components/notifications/notifications"
import type {Notification} from "@/library/notifications/types"
import type {App} from "vue";
import {emit} from "@/library/bus";

declare global {
	interface Window { Pusher: any; }
}

window.Pusher = Pusher;

let $echo: Echo

// Stores the private channel reference. This is useful for listening for specific events
// or notifications in various components throughout the client.
let $private: Channel

export default {
	install(app: App, options?: any) {
		$echo = new Echo({
			broadcaster: 'reverb',
			...options,
			enabledTransports: ['ws', 'wss'],
			authorizer: (channel: {name: string}) => {
				return {
					authorize: (socketId: string, callback: Function) => {
						axios.post('/api/broadcasting/auth', {
							socket_id: socketId,
							channel_name: channel.name
						})
							.then(response => {
								callback(false, response.data);
							})
							.catch(error => {
								callback(true, error.response);
							});
					}
				};
			},
		})
		
		app.provide('$echo', 'echo')
	}
}

function joinPresence() {
	$echo.join('players')
}

function joinPrivate(playerId: string) {
	$private = $echo.private(`players.${playerId}`)
	
	$private.notification((notification: Notification) => {
		useNotificationStore().pushNotification(notification)
		emit(notification.type, notification);
	});
}

export {
	$echo,
	$private,
	joinPresence,
	joinPrivate,
}

