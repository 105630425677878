<template>
  <div>
    <div class="flex items-center space-x-6" v-show="!show">
      <div class="flex-grow">You do not have a password saved to your account. <button class="inline splash-link" @click="toggleShow">Create one?</button></div>
    </div>

    <form method="post" @submit.prevent="submissionHandler" class="block space-y-6" v-if="show">
      <div class="flex">
        <label class="w-1/3 py-2">New password</label>
        <div class="flex-grow">
          <div class="flex-grow flex items-center space-x-1.5">
            <Text :type="fieldType" v-model="fields.password" :validation="validator.password" class="flex-grow"/>
            <Button @click="toggleVisibility">
              <Eye class="size-6" :open="!passwordsVisible"/>
            </Button>
          </div>
          <div class="relative" v-if="validator.password.$dirty">
            <Errors :form-object="validator.password" class="inset w-full mx-auto bg-black/50 p-2 backdrop-blur"/>
          </div>
        </div>
      </div>

      <div class="flex">
        <label class="w-1/3 py-2">Confirm password</label>
        <div class="flex-grow">
          <Text :type="fieldType" v-model="fields.passwordConfirmation" :validation="validator.passwordConfirmation"/>
          <div class="relative" v-if="validator.passwordConfirmation.$dirty">
            <Errors :form-object="validator.passwordConfirmation" class="inset w-full mx-auto bg-black/50 p-2 backdrop-blur"/>
          </div>
        </div>
      </div>

      <div class="pt-6 ml-auto w-2/3 flex items-center space-x-3">
        <Submit :disabled="!!validator.$errors.length || !validator.$anyDirty" :waiting="inProgress" class="w-20">Save</Submit>
        <Button @click="show = false">Cancel</Button>
      </div>
    </form>
	</div>
</template>

<script setup lang="ts">
  import {computed, ref} from 'vue'
	import Button from '@/components/form/Button.vue'
	import Errors from '@/components/form/Errors.vue'
	import {useAuthenticatorsStore} from '@/modules/identity/authentication/authenticators'
  import {required, minLength, sameAs} from '@vuelidate/validators'
  import {setPassword} from '@/modules/identity/account/profile'
  import Text from "@/components/form/Text.vue"
  import Eye from "@/components/icons/Eye.vue"
  import hasVisibility from "@/modules/identity/account/password-visibility"
  import Submit from "@/components/form/Submit.vue";
  import {useForm} from "@/components/form/form";
  import {useNotificationStore} from "@/components/notifications/notifications";
  import type {AuthenticationResponse} from '@/modules/identity/account/profile'

  const notificationStore = useNotificationStore()
  const { fromResponse } = notificationStore
	const authenticators = useAuthenticatorsStore()
	const show = ref(false)
  const { fieldType, passwordsVisible, toggleVisibility } = hasVisibility()

  const {fields, inProgress, validator, submissionHandler} = useForm()
    .hasFields({
      password: '',
      passwordConfirmation: '',
    })
    .withRules((fields) => ({
      password: {
        required,
        minLength: minLength(8)
      },
      passwordConfirmation: {
        required,
        sameAsRef: sameAs(computed(() => fields.password))
      }
    }))
    .whenSubmitted((fields) =>
        setPassword(fields).then((response: AuthenticationResponse) => {
          authenticators.authenticators = response.data.metaData.authenticators
          fromResponse('global', response.data)
          show.value = !show.value
        })
    )
    .create()

	const toggleShow = () => {
		show.value = !show.value
	}
</script>
