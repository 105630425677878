import {defineStore} from 'pinia'
import {ref} from "vue";

export const useAuthenticatorsStore = defineStore('authenticators', () => {
	const authenticators = ref([])
	
	function hasProvider(provider: string) {
		return authenticators.value.indexOf(provider) !== -1
	}
	
	return {
		authenticators,
		hasProvider,
	}
})
