<template>
	<div class="space-y-12">
    <form class="flex space-x-1.5" @submit.prevent="submissionHandler">
      <div class="flex-grow">
        <Text type="email" v-model="fields.email" :validation="validator.email"/>
        <div class="relative" v-if="validator.email.$dirty">
          <Errors :form-object="validator.email" class="absolute inset w-full mx-auto bg-black/50 p-2 backdrop-blur" />
        </div>
      </div>
      <Submit :disabled="!validator.email.$dirty && !validator.email.$error" :waiting="inProgress">Change</Submit>
    </form>
	</div>
</template>

<script setup lang="ts">
  import {email, required} from '@vuelidate/validators'
	import Errors from '@/components/form/Errors.vue'
	import {usePlayerStore} from '@/modules/identity/player'
  import {requestEmailChange} from '@/modules/identity/account/profile'
  import Text from "@/components/form/Text.vue"
  import {useForm} from "@/components/form/form"
  import {useNotificationStore} from "@/components/notifications/notifications"
  import type {AuthenticationResponse} from "@/modules/identity/account/profile"
  import Submit from "@/components/form/Submit.vue";

  const playerStore = usePlayerStore()
  const notificationStore = useNotificationStore()
  const { fromResponse } = notificationStore

  const {fields, inProgress, validator, submissionHandler} = useForm()
    .hasFields({
      email: playerStore.player.email,
    })
    .withRules(() => ({
      email: {
        email,
        required
      }
    }))
    .whenSubmitted((fields: {email: string}) =>
        requestEmailChange(fields.email).then((response: AuthenticationResponse) => {
          fromResponse('global', response.data)
        })
    )
    .create()
</script>
