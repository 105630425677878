<template>
  <Transition
    enter-from-class="opacity-0 blur-lg"
    enter-active-class="transition-all delay-200 duration-500"
    enter-to-class="opacity-100 blur-none"
    leave-from-class="opacity-100 blur-none"
    leave-active-class="transition-all duration-500"
    leave-to-class="opacity-0 blur-lg"
  >
    <div class="fixed flex flex-col top-0 left-0 right-0 bottom-0 backdrop-blur-xl backdrop-contrast-75 bg-black/60 z-overlay-600" v-if="accountPanelOpen">
      <Borders/>
      <div class="relative m-24 z-main-100 space-y-12">
        <header>
          <div class="inline-block mb-12">
            <Button @click="closeAccountPanel">
              <Icon class="size-5 text-white group-active:text-sky-500 stroke-current stroke-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
              </Icon>
              <span>Back</span>
            </Button>
          </div>
          <div class="flex space-x-24">
            <h1 class="text-6xl w-1/4 max-w-[280px]">Account</h1>
            <nav class="flex items-center space-x-6">
              <Button @click="activateTab('profile')" :rounded="true" :active="activeTab === 'profile'">
                <Icon class="fill-current size-7">
                  <path fill-rule="evenodd" d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" clip-rule="evenodd" />
                </Icon>
                <span class="text-xl">Profile</span>
              </Button>

              <Button @click="activateTab('authentication')" :rounded="true" :active="activeTab === 'authentication'">
                <Lock class="fill-current size-6"/>
                <span class="text-xl">Authentication</span>
              </Button>

              <Button @click="activateTab('notifications')" :rounded="true" :active="activeTab === 'notifications'">
                <Icon class="fill-current size-6">
                  <path d="M5.85 3.5a.75.75 0 0 0-1.117-1 9.719 9.719 0 0 0-2.348 4.876.75.75 0 0 0 1.479.248A8.219 8.219 0 0 1 5.85 3.5ZM19.267 2.5a.75.75 0 1 0-1.118 1 8.22 8.22 0 0 1 1.987 4.124.75.75 0 0 0 1.48-.248A9.72 9.72 0 0 0 19.266 2.5Z" />
                  <path fill-rule="evenodd" d="M12 2.25A6.75 6.75 0 0 0 5.25 9v.75a8.217 8.217 0 0 1-2.119 5.52.75.75 0 0 0 .298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 1 0 7.48 0 24.583 24.583 0 0 0 4.83-1.244.75.75 0 0 0 .298-1.205 8.217 8.217 0 0 1-2.118-5.52V9A6.75 6.75 0 0 0 12 2.25ZM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 0 0 4.496 0l.002.1a2.25 2.25 0 1 1-4.5 0Z" clip-rule="evenodd" />
                </Icon>
                <span class="text-xl">Notifications</span>
              </Button>
            </nav>
          </div>
        </header>

        <main>
          <Suspense>
            <Profile v-if="activeTab == 'profile'"/>
          </Suspense>
          <Suspense>
            <Authentication v-if="activeTab == 'authentication'"/>
          </Suspense>
          <Suspense>
            <Notifications v-if="activeTab == 'notifications'"/>
          </Suspense>
        </main>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
  import { useAccountStore } from "../account-store"
  import {storeToRefs} from "pinia"
  import Borders from "@/components/ui/Borders.vue"
  import Icon from "@/components/Icon.vue";
  import Profile from "@/modules/identity/account/settings/Profile.vue";
  import {ref} from "vue";
  import Button from "@/components/form/Button.vue";
  import Authentication from "@/modules/identity/account/settings/Authentication.vue";
  import Lock from "@/components/icons/Lock.vue";
  import Notifications from "@/modules/identity/account/settings/Notifications.vue";

  const accountStore = useAccountStore()
  const { accountPanelOpen } = storeToRefs(accountStore)
  const { closeAccountPanel } = accountStore

  const activeTab = ref<string>('profile')

  function activateTab(tab: string) {
    activeTab.value = tab
  }
</script>
