<template>
  <div class="flex justify-between items-center md:hidden w-full">
    <RouterLink :to="{name: 'home'}" class="block relative z-50 ml-4 sm:ml-8">
      <Logo size="small" class="w-12" alt="Small Halcyon Online logo"/>
    </RouterLink>

    <button class="p-1 relative z-50 m-4 sm:m-8 rounded-sm backdrop-blur active:shadow-outer-glow active:shadow-sky-300" @click.prevent="toggleNavigation">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 sm:w-8 sm:h-8">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" v-if="!open" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" v-else />
      </svg>
    </button>

    <Transition
        enter-from-class="opacity-0 max-h-0"
        enter-active-class="transition duration-500"
        enter-to-class="opacity-100 max-h-full"
        leave-from-class="opacity-100"
        leave-active-class="transition duration-500"
        leave-to-class="opacity-0"
    >
      <div class="fixed inset-0 w-full h-full bg-black/30 backdrop-blur-lg flex flex-col items-center justify-center text-2xl text-center tracking-widest p-16" v-if="open">
        <SplashBorder class="left-0 top-0 z-40"/>
        <SplashBorder class="right-0 top-0 rotate-90 z-40"/>
        <SplashBorder class="right-0 bottom-0 rotate-180 z-40"/>
        <SplashBorder class="left-0 bottom-0 rotate-270 z-40"/>
        <div class="w-full *:p-2 *:w-full *:border-t *:border-b *:border-transparent *:justify-center active:justify-between">
          <MobileNavLink :to="{name: 'home'}" @click="toggleNavigation">Home</MobileNavLink>
          <MobileNavLink :to="{name: 'home'}" @click="toggleNavigation">Guide</MobileNavLink>
          <MobileNavLink :to="{name: 'home'}" @click="toggleNavigation">Lore</MobileNavLink>
          <MobileNavLink :to="{name: 'home'}" @click="toggleNavigation">About</MobileNavLink>
          <MobileNavLink :to="{name: 'home'}" @click="toggleNavigation">Discord</MobileNavLink>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
  import {ref} from "vue";
  import MobileNavLink from "@/components/navigation/MobileNavLink.vue"
  import SplashBorder from "@/components/ui/SplashBorder.vue"
  import Logo from "@/components/shared/Logo.vue"

  const open = ref(false)

  function toggleNavigation() {
    open.value = !open.value;
  }
</script>
